import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import ZoneDate from '../components/timezone-components/zone-date'
import ZoneDates from '../components/timezone-components/zone-dates'

import TimezoneConverter from '../components/timezone-components/timezone-converter'

const TimezoneConverterPage = props => {
    var [date, setDate] = useState(new Date())
    
    useEffect(()=>{
        var timer = setInterval(()=> setDate(new Date()), 1000)

        return function cleanup(){
            clearInterval(timer)
        }
    })
    return(
        <Layout
            langKey="en"
            slug="/"
        >
            <section className="hero is-fullheight">
                
                <TimezoneConverter></TimezoneConverter>
            </section>
        
        </Layout>  
    )

 
}

export default TimezoneConverterPage
