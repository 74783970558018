import React, { useEffect, useRef, useState } from "react";
import useAssembleTimezoneName from "../timezone-components/functions/useAssembleTimezoneName";

const SearchableDropdown = ({
  options,
  label,
  id,
  selectedVal,
  keySelected,
  handleChange,
  reset,
  wrapperSetReset
}) => {
  const [query, setQuery] = useState(selectedVal);
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);
 
  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

	// assemble the timezone text + abbreviation
	const assembleTimezoneName = useAssembleTimezoneName();

  const selectOption = (option) => {
    
    const getValue =assembleTimezoneName(option.text, option.abbr, option.dstAbbr) 
    setQuery(() => getValue);
    handleChange(option);
    setIsOpen((isOpen) => !isOpen);
  };

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
  }



  const getDisplayValue = () => {
    if(!reset || query){
      wrapperSetReset();
      return query
    }

    if (selectedVal){
      setQuery(selectedVal)
      return selectedVal}

    return "";
  };

  const filter = (options) => {

	if(query){
    const getSelectedValue =  options.filter(
      (option) => { 
        if(option.isdst){
           return (option[label].toLocaleUpperCase().indexOf(query.toLocaleUpperCase()) > -1 || option.abbr.toLocaleUpperCase().indexOf(query.toLocaleUpperCase()) > -1 ||  option.dstAbbr.toLocaleUpperCase().indexOf(query.toLocaleUpperCase()) > -1  )
        }
        else{
console.log("options")
  console.log(option)
          return option[label].toLocaleUpperCase().indexOf(query.toLocaleUpperCase()) > -1 || option.abbr.toLocaleUpperCase().indexOf(query.toLocaleUpperCase()) > -1 
        }
      }
    );

    if(getSelectedValue.length>0){
      return getSelectedValue
    }
    else{
      const getFilteredValues = options.filter(item=>{
      const getAssembledValues = assembleTimezoneName(item.text, item.abbr, item.dstAbbr).toLocaleLowerCase()
        if(getAssembledValues === query.toLocaleLowerCase()){
          return item
        }
      })
      return getFilteredValues
    }	  
	}
	else{
		return options;
	}
  
  };

  return (
    <div key={id} className="dropdown">
      <div style={{width: "100%"}} >
        <div className="selected-value"  onClick={(e)=>toggle(e)}>
          <input className="input"
            ref={inputRef}
            autoComplete="off"
            type="text"
            value={getDisplayValue()}
            name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value);
              handleChange(null);
            }}
            onClick={(e)=>toggle(e)}
          />
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`} ></div>
      </div>

      <div className={`options ${isOpen ? "open" : ""}`}>
        {filter(options).map((option, index) => {
          return (
            <div 
              onClick={() => selectOption(option)}
              className={`option ${
                option[label] === selectedVal ? "selected" : ""
              }`}
              key={`${id}-${index}`}
            >
              {`${assembleTimezoneName(option.text, option.abbr, option.dstAbbr)}` }
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchableDropdown;
